<script lang="ts" setup>
const { t } = useT();

defineEmits<{ (event: "toggleSidebar"): void }>();

const { open } = useTaoModals();
const { playMagicAudio } = useMagicBoxAudios();
const { isSpecialBox } = useSpecialMagicBox();

const handleMagicBoxClick = () => {
	open("LazyOModalMagicBoxes");
	playMagicAudio();
};
</script>
<template>
	<div class="mob-navigation">
		<div class="navigation-games" @click="handleMagicBoxClick">
			<AText variant="topeka" :modifiers="['bold']">
				{{ t("YOU CAN NOW OPEN MAGIC BOXES") }}
			</AText>
			<NuxtImg
				src="/nuxt-img/magic-box/magic-mob-menu.png"
				alt="box"
				width="150"
				height="64"
				format="avif"
				loading="lazy"
			/>
		</div>

		<div class="mob-list">
			<NuxtLink to="/issues/all-games/" class="mob-item">
				<NuxtIcon class="icon-slots" name="20/slots" filled />
				<AText type="xx-small" :modifiers="['uppercase', 'medium']">{{ t("Games") }}</AText>
			</NuxtLink>

			<NuxtLink to="/promotions/" class="mob-item">
				<NuxtIcon name="20/promotion" filled />
				<AText type="xx-small" :modifiers="['uppercase', 'medium']">{{ t("Promo") }}</AText>
				<AText :modifiers="['semibold']" :size="9" class="counter text-coro">1</AText>
			</NuxtLink>

			<AText
				as="div"
				type="xx-small"
				:modifiers="['uppercase', 'medium']"
				class="mob-item"
				@click="handleMagicBoxClick"
			>
				<NuxtIcon :name="`20/${isSpecialBox ? 'lanterns' : 'chest'}`" filled />
				{{ isSpecialBox ? t("Magic Lanterns") : t("Magic box") }}
			</AText>

			<AText as="div" type="xx-small" :modifiers="['uppercase', 'medium']" class="mob-item">
				<ONotificationCenter />
				{{ t("Messages") }}
			</AText>

			<AText
				as="div"
				type="xx-small"
				:modifiers="['uppercase', 'medium']"
				class="mob-item"
				@click="$emit('toggleSidebar')"
			>
				<NuxtIcon name="20/burger" filled />
				{{ t("More") }}
			</AText>
		</div>
	</div>
</template>
<style scoped lang="scss">
@keyframes zoom {
	0% {
		transform: scale(1) translateY(0);
	}
	50% {
		transform: scale(1.2) translateY(8%);
	}
	100% {
		transform: scale(1) translateY(0);
	}
}

.mob-navigation {
	position: fixed;
	bottom: 0;
	width: 100%;
	height: 64px;
	z-index: 13;

	@include media-breakpoint-up(lg) {
		display: none;
	}
}

.navigation-games {
	position: absolute;
	z-index: 11;
	left: 0;
	bottom: calc(100% - 10px);
	width: 100%;
	height: 54px;
	padding: 16px 16px 0;
	display: flex;
	align-items: flex-start;
	background: var(--gurgaon);
	border-radius: 16px 16px 0 0;

	&:hover {
		cursor: pointer;
	}

	img {
		position: absolute;
		top: -10px;
		right: 0;
		animation: zoom 2s ease-out infinite;
	}
}

.mob-list {
	width: 100%;
	height: 100%;
	background-color: var(--gray-800);
	box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.24);
	border-radius: 16px 16px 0 0;
	padding: 8px 12px;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	position: relative;
	z-index: 12;
}

.mob-item {
	display: flex;
	flex-direction: column;
	align-items: center;
	color: var(--gray-200);
	min-width: 40px;
	position: relative;

	.nuxt-icon,
	.notification-center {
		font-size: 20px;
		width: 20px;
		height: 20px;
		display: flex;
		text-align: center;
		align-items: center;
		justify-content: center;
		margin-bottom: 8px;
		color: var(--neutral);
	}

	.notification-center:deep(.icon) {
		color: var(--neutral);
	}

	&.router-link-active {
		color: var(--primary);
		.nuxt-icon {
			color: inherit;
		}
	}

	.counter {
		position: absolute;
		top: -2px;
		right: 5px;
		border-radius: 50%;
		width: 12px;
		height: 12px;
		background-color: var(--camaguey);
		line-height: 1;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
</style>
